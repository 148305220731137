
import Vue from 'vue';
import { mapActions } from 'vuex';

import { Routes } from '@/router/routes';
import { Services, Models } from '@/injectables/tokens';
import { AuthServiceContract, C360ModelContract, EnvServiceContract, LocalStorageServiceContract } from '@/injectables';

export default Vue.extend({
  name: 'Login',

  useInjectable: [Services.Auth, Services.LocalStorage, Services.Env, Models.C360],

  data(): {
    error: string | undefined;
    email: string;
    password: string;
    loading: boolean;
    tokenLoading: boolean;
    resetPassword: boolean;
  } {
    return {
      error: '',
      email: '',
      password: '',
      loading: false,
      tokenLoading: false,
      resetPassword: false,
    };
  },

  computed: {
    whiteLabeledEnv(): boolean {
      const url = window.location.host;

      return (this[Services.Env] as EnvServiceContract).isWhiteLabeledEnv(url);
    },
  },

  created(): void {
    if (this.whiteLabeledEnv) {
      const nextUri = this.$route.query.nextUrl
        ? `${window.location.origin}${this.$route.query.nextUrl}`
        : window.location.origin;

      if (!this.$store.state.user) {
        // TODO: move to router?
        const link = (this.c360Entity as C360ModelContract).getC360LoginLink(this.currentEnv());

        window.location.href = `${link}?redirectUri=${nextUri}`;
      } else {
        this.$router.push({ name: Routes.Home, query: this.$route.query.nextUrl });
      }
    }
  },

  mounted() {
    if (this.$route.query && this.$route.query.token) {
      this.handleToken(this.$route.query.token as string);
    }
  },

  methods: {
    ...mapActions('auth', ['signinUser']),
    ...mapActions(['showSnackbar']),
    async sendResetPassword() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = this.$refs.form as any;
      this.error = undefined;

      if (!form.validate()) return;

      const url = window.location.origin;

      this.loading = true;
      const { isErr, unwrap } = await (this.authService as AuthServiceContract).sendResetPasswordEmail(this.email, url);
      this.loading = false;

      if (isErr()) {
        return;
      }

      const result = unwrap();

      if (!result) {
        await this.showSnackbar({
          content: "Can't send reset password email at this time",
          color: 'error',
        });
        return;
      }

      this.resetPassword = false;

      await this.showSnackbar({
        content: 'The letter was successfully sended. Check email to reset a password',
        color: 'success',
      });
    },
    async login() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = this.$refs.form as any;
      this.error = undefined;

      if (!form.validate()) return;

      this.loading = true;

      const { isErr, unwrap, unwrapErr } = await (this.authService as AuthServiceContract).signIn(
        this.email.toLowerCase(),
        this.password,
      );

      if (isErr()) {
        const err = unwrapErr().message;
        this.loading = false;
        this.$log('error', 'signin/handleSigninUser', err);
        return;
      }

      const { access, refresh } = unwrap();

      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_refresh', refresh);

      this.loading = false;
      await this.showSnackbar({ content: 'You are now signed in', color: 'success' });
      this.handleAccessToken(access);
    },
    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },
    async handleAccessToken(token: string): Promise<void> {
      (this[Services.LocalStorage] as LocalStorageServiceContract).setItem('mp_c360_access', token);

      await this.$store.dispatch('auth/checkIfAuth');

      const nextUrl = this.$route.query.nextUrl;

      if (!nextUrl) {
        this.$router.push({ name: Routes.Home });
      } else {
        window.location.href = nextUrl;
      }
    },
    handleToken(token: string): void {
      this.tokenLoading = true;
      this.$store
        .dispatch('tokenlogin', token)
        .then(user => {
          this.tokenLoading = false;
          if (!user.email) {
            this.error = user.error || 'tokenlogin failed';
            return;
          }
          if (this.$route.query.nextUrl) {
            this.$router.push(this.$route.query.nextUrl as string);
          } else {
            this.$router.push({ name: Routes.Home });
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .catch((error: any) => {
          this.tokenLoading = false;
          // eslint-disable-next-line no-console
          console.error('tokenlogin error', error);
        });
    },
  },
});
